$(document).on('click', '.reservatButon', changeDate);
// $(document).on('change', '.js_reservation-station-from', changeStationFrom)
$(document).on('change', '.js_city_from_id', changeCityFrom);
$(document).on('change', '.js_city_to_id', changeCityTo);
//$(document).on('change', '.js_reservation-station-to', checkStateButtonDisabled)
// $(document).on('change', '.js_reservation-station-to', changeStationTo)
// $(document).on('change', '.js_reservation-route', changeRoute)
$(document).on('click', '.js_reservation-button', clickButton2);
$(document).on('click', '.js_reservation-return-button', reservationReturn);
$(document).on('ready', changeImg);
$(document).on('change', '.js_city_to_id', disableDates);

function changeImg() {
    $.get("/get-rand-img").done(function (data) {
        if (data !== null) {
            if (window.matchMedia("(max-width: 767px)").matches) {
                if (data['mobile']) {
                    $('body.mainPage, body.index, body.personalCabinet, body.about').css('background-image',
                        'url(/public/assets/index/images/for_clients/' + data['mobile'] + ')').css('background-size', 'cover');
                }
            } else {
                if (data['desktop']) {
                    $('body.mainPage, body.index, body.personalCabinet, body.about').css('background-image',
                        'url(/public/assets/index/images/for_clients/' + data['desktop'] + ')').css('background-size', 'cover');
                }
            }
        }
    });
}

// Автоприменение формы, свели перешли из iframe
(function () {
    const $form = document.querySelector('form#reservations');
    const $from = document.querySelector('.js_city_from_id');
    const $to = document.querySelector('.js_city_to_id');
    const $date = document.querySelector('.js_date-pick');
    const $date_return = document.querySelector('.js_date-pick-return');
    const $time = document.querySelector('.js_time-pick');
    let $return_flag = document.querySelector('#return_flag');
    let params = {};

    if (isAllElementsExists()) {
        init();
    }

    function isAllElementsExists() {
        return $form && $from && $to && $date;
    }

    function init() {
        setParams();
        if (isQueryValid()) {
            setForm();
        }
    }

    function setParams() {
        var url = location.href;
        url = url.split('?');

        if (url[1]) {
            var query = url[1].split('&');

            for (var i = 0; i < query.length; i++) {
                var pair = query[i].split('=');
                if (pair[1]) {
                    params[pair[0]] = pair[1];
                }
            }
        }
    }

    function setForm() {
        $date.value = params.date;
        if (params.return_date && $date_return) {
            $date_return.value = params.return_date;
        }
        if (params.places !== undefined) {
            var placeCount = Number(params.places);
            if (placeCount > 1 && placeCount < 6) {
                $('.js_places-input').val(placeCount);
                $('.js_places-item').attr('data-count', placeCount).data('count', placeCount);
                $('.js_places-control').prop('disabled', false);
                if (placeCount == 5) {
                    $('.js_places-control').first().prop('disabled', true);
                }
            }
        }

        if (params.time !== undefined) {
            $("#custom-time-from").trigger('click');
            $time.value = params.time;
        }

        var date = $($date).val();
        var $bts = $($date).closest('form').find('.reservatButon');
        $bts.removeClass('active');
        var $btn = $bts.filter('[data-val="' + date + '"]');
        if ($btn.length) $btn.addClass('active');

        var $bts_return = $($date_return).closest('form').find('.reservatButon');
        $bts_return.removeClass('active');
        var $btn_return = $bts_return.filter('[data-val="' + $($date_return).val() + '"]');
        if ($btn_return.length) $btn_return.addClass('active');

        $from.value = params.from;
        params.return_flag ? $return_flag.value = params.return_flag : $return_flag = null;
        $($return_flag).trigger('change');

        var url = $($from).data().url;
        var value = $($from).val();
        let frame_id = $('#frame_id').val();

        $(".js_city_to_id option").remove();
        $('.js_city_to_id').prop('disabled', false);
        $(".js_reservation-station-from option").remove();
        $('.js_reservation-station-from').append($('<option>', {
            value: 0,
            text: 'Место посадки (Откуда)'
        }));

        $(".js_reservation-station-to option").remove();
        $('.js_reservation-station-to').append($('<option>', {
            value: 0,
            text: 'Место высадки (Куда)'
        }));

        $.get(url + '?city_from_id=' + value + (frame_id ? '&embed=' + frame_id : ''), function (response) {
            $('.js_city_to_id').append($('<option>', {
                value: 0,
                text: 'Куда'
            }));
            for (var key in response) {
                $('.js_city_to_id').append($('<option>', {
                    value: response[key].id,
                    text: response[key].name
                }));
            }

            $to.value = params.to;

            changeCityTo();

            showTours();
        });

    }

    function isQueryValid() {
        return params.from >= 0
            && params.from.match(/\d+/)
            && params.to.match(/\d+/)
            && params.date.match(/\d{4}-\d{2}-\d{2}/)
            && (params.return_flag ? params.return_flag.match(/\d{1}/) : true)
            ;
    }
})();

function changeCityFrom() {
    clearStations(true);
    $(".js_label_station_from").html("Посадка: " + $(this).find('option:selected').text())

    $('.city_to_id').hide();
    $('.js_bus-overlay.js_city_to').show();

    let url = $(this).data().url;

    let value = $(this).val();
    let frame_id = $('#frame_id').val();
    $(".js_city_to_id option").remove();
    $('.js_city_to_id').prop('disabled', false);


    $.get(url + '?city_from_id=' + value + (frame_id ? '&embed=' + frame_id : ''), (response) => {
        $('.js_city_to_id').append($('<option>', {
            value: 0,
            text: 'Куда'
        }));
        for (var key in response) {
            $('.js_city_to_id').append($('<option>', {
                value: response[key].id,
                text: response[key].name
            }));
        }

        if ($('.js_city_to_id') && $('.js_city_to_id').val() && $('#return_flag')) {
            if (parseInt($('#city_to_id').val()) > 0 && $('#city_from_id').val()) {
                $('#return-from').val($("#city_to_id option:selected").text());
                $('#return_city_from_id').val($("#city_to_id").val());

                disableDates();
            }

            if ($('#return_flag').val() == 1) {
                $('.js-return-ticket, .scheduleBlockReturn').fadeToggle();
                $('.reservRound').eq(0).text(this.checked ? 'Туда' : 'Бронь');

                if ($('#return-from').val() && $('#return-to').val()) {
                    reservationReturn();
                }
            }
        }

        if ($('.js_city_from_id').is('[data-changed]')) {
            $('.js_city_to_id').val($('.js_city_from_id').attr('data-changed')).trigger('change');
            $('.js_city_from_id').removeAttr('data-changed');
            $('.js_station_change').attr('disabled', false);
        }

        if ($('.js_city_from_id').is('[data-preselect]')) {
            $('.js_city_to_id').val($('.js_city_from_id').attr('data-preselect')).trigger('change');
            $('.js_city_from_id').removeAttr('data-preselect');
        }

        $('.js_bus-overlay.js_city_to').hide();
        $('.city_to_id').show();
    });


}

function changeCityTo() {
    let button = $('.js_reservation-button');
    button.attr('disabled', false);
    $(".js_label_station_to").html("Высадка: " + $(this).find('option:selected').text())

    if (document.getElementById('station_from_id')) {
        clearStations();
        setStations($("#city_to_id").data().station_url, $("#city_from_id").val(), $("#city_to_id").val());
    }
}

async function setStations(station_url, cityFromId, cityToId) {
    $(".js_station_from_id option").remove();
    $(".js_station_to_id option").remove();
    if (document.getElementById('station_from_id')) {
        let elFromStations = $('.js_station_from_id');
        let elToStations = $('.js_station_to_id');

        $.get(station_url + '?city_from_id=' + cityFromId + '&city_to_id=' + cityToId, (response) => {
            for (let key in response['fromStations']) {
                elFromStations.append($('<option>', {
                    value: response['fromStations'][key]['id'],
                    text: response['fromStations'][key]['name'],
                    selected: response['fromStations'][key]['selected'],
                }));
            }

            for (let key in response['toStations']) {
                elToStations.append($('<option>', {
                    value: response['toStations'][key]['id'],
                    text: response['toStations'][key]['name'],
                    selected: response['toStations'][key]['selected'],
                }));
            }

            //Для фрейма костыль (не работает async или не правильно реализую)
            const queryStationFrom = new URL(window.location.href).searchParams.get('from_station')
            if (queryStationFrom) {
                elFromStations.val(queryStationFrom).change();
            }
            const queryStationTo = new URL(window.location.href).searchParams.get('to_station')
            if (queryStationTo) {
                elToStations.val(queryStationTo).change();
            }
        });

    }
}

function clearStations(disabled = false) {
    if (document.getElementById('station_from_id')) {
        let elFromStations = $('.js_station_from_id');
        elFromStations.prop('disabled', disabled);
        $(".js_station_from_id option").remove();
        elFromStations.append($('<option>', {
            value: 0,
            text: 'Остановка посадки'
        }));

        let elToStations = $('.js_station_to_id');
        elToStations.prop('disabled', disabled);
        $(".js_station_to_id option").remove();
        elToStations.append($('<option>', {
            value: 0,
            text: 'Остановка высадки'
        }));
    }
}

function clickButton() {
    if ($('.js_reservation-button').attr('disabled')) {
        toastr.error('Выберите места посадки и высадки');
    } else {
        var url = $('.js_reservation-button').attr('data-url') + '?' + $('.js_reservation-button').closest('form').serialize();
        var show_schedule = $('.scheduleBlock');
        $.get(url, (response) => {
            show_schedule.html(response);
        })
    }
    return false
}

function clickButton2() {
    if ($('.js_reservation-button').attr('disabled')) {
        toastr.error('Выберите города посадки и высадки');
    } else {
        showTours();
    }
    return false
}

function showTours() {
    if ($('.scheduleBlockWrapper').length) {
        $('.scheduleBlockWrapper').addClass('is-loading');
    }

    //Для фрейма костыль (скорее всего из-за того что async function setStations не срабатывает)
    let query = $('.js_reservation-button').closest('form').serialize();
    const queryStationFrom = new URL(window.location.href).searchParams.get('from_station')
    const queryStationTo = new URL(window.location.href).searchParams.get('to_station')
    if (queryStationFrom && !query.includes('station_from_id')) {
        query = query + '&station_from_id=' + queryStationFrom + '&station_to_id=' + queryStationTo
    }
    let url = $('.js_reservation-button').attr('data-url') + '?' +query;
        show_schedule = $('.scheduleBlock').length ? $('.scheduleBlock') : false,
        show_schedule_return = $('.scheduleBlockReturn').length ? $('.scheduleBlockReturn') : false;

    $.get(url, function (response) {
        if ($('.scheduleBlockWrapper').length) {
            $('.scheduleBlockWrapper').removeClass('is-loading');
        }
        if (response.result == 'success') {
            var $embedForm = $('.js-embed-form').length ? $('.js-embed-form') : false;

            if ($embedForm) {
                if (typeof sendEmbedReservationForm === "function") {
                    sendEmbedReservationForm();
                }
            } else {
                if (show_schedule.length) {
                    show_schedule.html(response.html);
                    $('html, body').animate({
                        scrollTop: show_schedule.offset().top
                    }, 300);
                }

                if (show_schedule_return.length) {
                    show_schedule_return.hide();
                    show_schedule_return.html(response.return_html);
                    show_schedule_return.show();
                }
            }
        } else {
            toastr.error(response.message);
        }
    });
}

function reservationReturn() {
    if (!$('#return_city_to_id').val() || !$('#return_city_from_id').val()) {
        toastr.error('Выберите города посадки и высадки');
    } else {
        $('.scheduleBlockReturn').html('');
        $('.sk-fading-circle.second').show();
        var url = $('.js_reservation-button').attr('data-url') + '?' + $('.js_reservation-return-button').closest('form').serialize();
        var show_schedule = $('.scheduleBlockReturn');
        $.get(url, function (response) {
            $('.sk-fading-circle.second').hide();
            if (response.result == 'success') {
                show_schedule.html(response.html);
                $('html, body').animate({
                    scrollTop: show_schedule.offset().top
                }, 300);
                show_schedule.prepend('<input type="hidden" class="return-form" value="1"/>');
            } else {
                toastr.error(response.message);
            }
        });
    }
    return false;
}

function changeRoute() {
    let routeId = $(this).val()
    if ($(this).val()) {
        $.post(`/stations?route_id=${routeId}`, (response) => {
            if (response.result = 'success') {
                let $stationFrom = $('.js_reservation-station-from');
                $stationFrom.find(`option`).not(':first').remove();
                $.each(response.stations, function (index, value) {
                    if (index == 0) {
                        $stationFrom.append(`<option selected value="${value.station_id}">${value.name}</option>`);
                        let $stationTo = $('.js_reservation-station-to');
                        $stationTo.attr('disabled', true);
                        $.post(`/stations?route_id=${routeId}&station_from_id=${value.station_id}`, (response) => {
                            if (response.result = 'success') {
                                $stationTo.find(`option`).not(':first').remove();
                                length = response.stations.length;
                                $.each(response.stations, function (index, value) {
                                    if (index === (length - 1))
                                        $stationTo.append(`<option selected value="${value.station_id}">${value.name}</option>`)
                                    else
                                        $stationTo.append(`<option value="${value.station_id}">${value.name}</option>`)
                                });
                            }
                        })
                        checkStateStationToDisabled()
                        checkStateButtonDisabled()
                    } else
                        $stationFrom.append(`<option value="${value.station_id}">${value.name}</option>`);
                });
                checkStateStationToDisabled()
                checkStateButtonDisabled()
                $('.scheduleBlock').html('');
                return;
            }
        })
    }
    $('.scheduleBlock').html('');
    checkStateStationToDisabled()
    checkStateButtonDisabled()
}

function changeStationFrom() {
    let $stationTo = $('.js_reservation-station-to');
    $stationTo.attr('disabled', true);
    let routeId = $('.js_reservation-route').val()
    let stationFromId = $(this).val();
    if (stationFromId) {
        $.post(`/stations?route_id=${routeId}&station_from_id=${stationFromId}`, (response) => {
            if (response.result = 'success') {
                $stationTo.find(`option`).not(':first').remove();

                length = response.stations.length;
                $.each(response.stations, function (index, value) {
                    if (index === (length - 1))
                        $stationTo.append(`<option selected value="${value.station_id}">${value.name}</option>`)
                    else
                        $stationTo.append(`<option value="${value.station_id}">${value.name}</option>`)
                });
                checkStateStationToDisabled()
                checkStateButtonDisabled()
                setTimeout(clickButton, 900);
                return;
            }
        })
    }
    checkStateStationToDisabled()
    checkStateButtonDisabled()
}

function checkStateStationToDisabled() {
    let $stationTo = $('.js_reservation-station-to');
    let disabled = true;
    if ($('.js_reservation-station-from').val()) {
        disabled = false
    } else {
        $stationTo.find(`option`).not(':first').remove();
    }
    $stationTo.attr('disabled', disabled)
}

function checkStateButtonDisabled() {
    let disabled = true;
    if ($('.js_reservation-station-to').val() && $('.js_reservation-station-from').val()) disabled = false
    $('.js_reservation-button').attr('disabled', disabled);
}

function changeStationTo() {
    let disabled = true;
    if ($('.js_reservation-station-to').val() && $('.js_reservation-station-from').val()) disabled = false
    $('.js_reservation-button').attr('disabled', disabled);
    setTimeout(clickButton, 300);
}

function changeDate() {
    var val = $(this).data('val');
    var $date = $(this).closest('form').find('.js_date-pick');
    $(this).closest('form').find('.reservatButon').removeClass('active');
    $(this).addClass('active');
    $date.val(val);
    $date.datepicker('setDate', val);
    if ($(this).data('return')) {
        reservationReturn();
    } else {
        showTours();
    }
    //setTimeout(clickButton2, 500);
}

$(document).on('change', '.js_date-pick', changeDatePicker);

function changeDatePicker() {
    var date = $(this).val();
    var $bts = $(this).closest('form').find('.reservatButon');
    $bts.removeClass('active');
    var $btn = $bts.filter('[data-val="' + date + '"]');
    if ($btn.length) $btn.addClass('active');
    //setTimeout(clickButton2, 500);
}

function disableDates() {
    var fromCity = $("select[name='city_from_id']").val();
    var toCity = $("#city_to_id").val();
    if (toCity && fromCity) {
        $('.js_date-pick').hide();
        $('.js_fordate .js_bus-overlay').show();
        $.get('/schedules/get_tour_dates', {city_from_id: fromCity, city_to_id: toCity}, function (array) {
            var html = $('.js_fordate').html();
            $('.js_fordate').html(html);
            $('.js_date-pick').datepicker({
                container: $('.reservationsWindWrapp').length ? '.reservationsWindWrapp' : 'body',
                beforeShowDay: function (date) {
                    var event = new Date(date);
                    event.setDate(event.getDate() + 1);
                    let dateDisable = JSON.stringify(event);
                    dateDisable = dateDisable.slice(1, 11);
                    return array.indexOf(dateDisable) !== -1;
                }
            });
            $('.js_date-pick').show();
            $('.js_fordate .js_bus-overlay').hide();
        });
    }
    $.get('/schedules/get_tour_dates', {city_from_id: toCity, city_to_id: fromCity}, function (array) {
        var html = $('.js_fordate2').html();
        $('.js_fordate2').html(html);
        $('.js_date-pick-return').datepicker({
            container: $('.reservationsWindWrapp').length ? '.reservationsWindWrapp' : 'body',
            beforeShowDay: function beforeShowDay(date) {
                var event = new Date(date);
                event.setDate(event.getDate() + 1); // todo: why add one day?
                var dateDisable = JSON.stringify(event);
                dateDisable = dateDisable.slice(1, 11);
                return array.includes(dateDisable);
            }
        });
    });
}
